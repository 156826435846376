<template>
  <div v-if="race && race.geofence">
    <v-dialog scrollable fullscreen v-model="visible">
      <template v-slot:activator="{ on }">
        <v-btn small text :color="color || event.color || 'primary'" v-on="on">
          <v-icon class="mr-2">fadl fa fa-draw-polygon</v-icon> {{$t('events.rules.view-geo-fence')}}
        </v-btn>
      </template>
      <v-card color="black">
        <v-toolbar dark color="grey darken-4">
          <v-btn icon dark class="ml-2" @click="visible = false">
            <v-icon>fa-times-circle</v-icon>
          </v-btn>
          <v-toolbar-title>{{race.name}} {{$t('events.rules.geo-fence-title')}}</v-toolbar-title>
        </v-toolbar>
        <div ref="map" id="map" class="full-dialog"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PolylineUtil from "@/plugins/Polyline.encoded.js";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "RaceGeoFenceDialog",
  components: {
  },
  props: {
    event: Object,
    race: Object,
    color: String,
  },
  data() {
    return {
      visible: false,
      zoom: 11,
      map: null,
      polygon: null,
      mapOptions: {
        zoomSnap: .25,
        maxZoom: 17,
        dragging: !window.L.Browser.mobile,
        tap: !window.L.Browser.mobile,
        touchZoom: true,
      },

    }
  },
  async mounted() {

  },
  methods: {

    async ensureMapLoaded() {
      await this.initMap();
      this.loadMap();
    },

    async initMap() {
      if (this.race == null || this.event == null || this.map != null) {
        console.log('No data (yet) to load.', this.race, this.event, this.map);
        return;
      }
      console.log('Loading results on map now.');

      await this.$nextTick();
      console.log('Preparing map.');
      if (!this.$refs.map) {
        return;
      }
      this.map = window.L.map('map', this.mapOptions);
      this.addDefaultTileLayer(this.map);
    },
    addDefaultTileLayer(map) {
      window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);
    },

    loadMap() {
      if (this.map == null) {
        console.log('Waiting for map to initialize.');
        return;
      }
      const decoded = this.race.geofence == null ? null : PolylineUtil.decode(this.race.geofence);
      console.log('Showing course map now:', decoded);

      // dim everything outside the poly
      const earth = [[90, -180],  [90, 180],  [-90, 180],  [-90, -180]];
      window.L.polygon([earth, decoded], { color: '#000000', opacity: 1, fillOpacity: 0.3, weight: 5, stroke: true, fill: true }).addTo(this.map);
      // add nice fat outline
      window.L.polygon(decoded, { color: '#ffffff', opacity: 1, weight: 10, stroke: true, fill: false, dashArray: null }).addTo(this.map);
      // add poly itself
      this.polygon = window.L.polygon(decoded, { color: '#8bc34a', opacity: 1, fillOpacity: 0.1, weight: 5, stroke: true, fill: false, dashArray: null }).addTo(this.map);
      this.map.setMaxBounds(null); // reset to prevent clipping when course is changed
      this.map.fitBounds(this.polygon.getBounds().pad(.1 /* 10% */));
      this.map.setMaxBounds(this.polygon.getBounds().pad(.5 /* 50% */));
    },

    clearOverlays: function(m) {
        for(const i in m._layers) {
            if(m._layers[i]._path != undefined) {
                try {
                    m.removeLayer(m._layers[i]);
                }
                catch(e) {
                    console.log("problem with " + e + m._layers[i]);
                }
            }
        }
    },
  },
  computed: {
    
  },
  watch: {
    async visible(value) {
      if (value) {
        await this.$nextTick();
        await this.ensureMapLoaded();
      } 
    },
    async race() {
      this.polygon = null;
      if (this.map) {
        this.clearOverlays(this.map);
      }
    }
  },
};
</script>

<style lang="scss">
  .full-dialog { width:100vw;height:100vh; }
</style>