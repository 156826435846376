<template>
  <div v-if="event && race">
    <TileMap ref="tileMap" v-if="tiles && tiles.length > 0" :tileOptionCreator="getTileStyle" preventAutoLoad tile-selected-event-name="tile-show-details" @selected="tileSelected"/>
    <div v-if="race.team" class="d-flex flex-row text-muted">
      <div class="shrink mx-2" style="white-space:nowrap;" >Tile shading based on color of each team</div>
    </div>
    <div v-else class="d-flex flex-row text-muted">
      <div class="shrink mx-2" style="white-space:nowrap;" >Tile shading based on popularity</div>
      <div class="grow mx-2" style="background-image: linear-gradient(to right, #63be7b, #81c879, #9ed177, #bbd978, #d8e17b, #e6da74, #f3d36f, #ffcb6e, #ffb364, #ff9a61, #ff8264, #f8696b);"></div>
      <div class="shrink mx-2" style="white-space:nowrap;">(most popular)</div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/eventbus.js';
import tileService from "@/services/tileService";
import eventService from "@/services/eventService";
import TileMap from "@/components/TileMap.vue";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "RaceResultsTileMap",
  components: {
    TileMap,
  },
  props: {
      event: Object,
      race: Object,
      meta: Object,
      badges: Array,
  },
  data() {
    return {
      tiles: null,
      detailResultColumns: [
        { type: 'POSITION', visible: true, css_class: 'col-position', caption_key: 'overall' },
        { type: 'AVATAR', visible: true, css_class: 'col-avatar' },
        { type: 'NAME', visible: true, css_class: 'col-name', caption_key: 'participant' },
        { type: 'SECONDARY_SCORE', visible: true, css_class: '', caption_key: 'score' },
      ],
    };
  },
 
  async mounted() {
    console.log('LISTENING TO tile-show-details');
    window.EventBus = EventBus; // so we can access it from leaflet popup
    EventBus.$on('tile-show-details', async id => {
      console.log('showing details of ', id);
      //const result = (await eventService.getRaceResultDetails(this.event.id, this.race.id, id)).data;
      this.$refs.tileMap.closePopups();
      //this.$emit('showResultDetails', result); 
    });
    EventBus.$on('tile-show-rankings', async id => {
      console.log('showing RANKINGS of ', id);
      this.loadTileResultsById(id);
    });

  },
  methods: {
    async loadResults(tiles) {
      if (this.race.scoring === 'TILES') {
        //this.tiles = (await eventService.getRaceTiles(this.eventId, this.raceId)).data.data;
        //this.tiles = tileBadges.map(x => (x));
        this.tiles = tiles;
        console.log('LOADING TILES with meta', this.meta, 'badges', this.badges); 
        await this.$nextTick();
        this.$refs.tileMap.loadTiles(this.tiles);

      }

    },

    getTileStyle(tile, zoom) {
      var opacity = 0;
      var color = tile.col;
      if (!tile.col && tile.pop > 0) {
        color = tile.pop <= .5 
              ? this.$helpers.getGradientColorAt('#63BE7B', '#FFEB84', tile.pop * 2)  // green -> yellow
              : this.$helpers.getGradientColorAt('#FFEB84', '#F8696B', (tile.pop-.5) * 2); // yellow -> red
      }
      if (color && zoom < 8) {
        opacity = .5;
      }
      else if (color && zoom <= 10) {
        opacity = .25;
      }
      else if (color) {
        opacity = .1;
      }


      return tile.fog 
             ? { fillColor: 'white', fillOpacity: .05, fill: true, weight: 1, color: '#aaa' } 
             : { fillColor: color || 'white', fillOpacity: opacity, fill: true, weight: 1, color: color || '#aaa' } ;
    },

    async tileSelected(tile) {
      //this.$refs.tileMap.highlightTile(tile);
      //var tileResults = (await eventService.getRaceTileResults(this.event.id, this.race.id, tile.id)).data.data;
      await this.loadTileResults(tile);
    },
    async loadTileResultsById(tileId) {
      const tile = (await tileService.get(tileId)).data;
      await this.loadTileResults(tile);

    },
    async clearSelection() {
      this.$refs.tileMap.highlightTile(/*tile*/ null, /*highlight =*/ false);
    },
    async loadTileResults(tile) {
      this.$refs.tileMap.highlightTile(tile, /*highlight =*/ true, /*showPopup =*/ false);
      var tileResults = (await eventService.getRaceTileResults(this.event.id, this.race.id, tile.id)).data.data;
      console.log('tileResults', tileResults);
      var data = {
        tile: tile,
        results: tileResults,
        columns: this.detailResultColumns,
        title: tile.name,
      };
      this.$emit('showresults', data);
    },

  },
  watch: {
  },
  computed: {
    
  },

};
</script>
<style lang="scss">


</style>

