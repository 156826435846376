<template>
  <div>
    <link rel="stylesheet" href="/flags-css/css/flag-icon.min.css">
    <v-skeleton-loader v-if="!results && $store.getters.loading" type="table"></v-skeleton-loader>
    <v-simple-table v-if="race && results" >
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="(item, idx) in columns" :key="idx" :class="item.css_class" :data-type="item.type">
              {{ item.caption ? item.caption : (item.caption_key ? $t('results.cols.'+item.caption_key) : (item.caption || '') ) }}
              
              <TooltipDialog v-if="item.type == 'SCORE' && race.scoring_desc" title="Scoring for this race" :text="race.scoring_desc" />
            </th>
            <th v-if="!$vuetify.breakpoint.xs"><!-- icons and actions --></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="showCollectiveProgress && !showSubResults" class="grey lighten-4">
            <td :colspan="columns.findIndex(x => x.type == 'NAME')">&nbsp;</td>
            <td class="col-name" style="min-width:120px;">
              <div class="font-weight-bold">{{$t('results.collective-progress')}}</div>
              <i class="font-weight-light">{{ $t(race.team ? 'results.collective-progress-by-all-teams' : 'results.collective-progress-by-all-participants') }}</i>
            </td>
            <td class="pr-0" colspan="99">
              <v-chip outlined :color="event.color || 'green'" :title="$options.filters.distance(meta.collective_progress, event.unit, false, race.activity_types)">
                <span v-if="race.scoring === 'TILES' && race.dist && !race.hide_collective_goal">
                  {{ meta.collective_progress }}
                  of
                  {{ race.dist }} tiles
                  ({{ ((meta.collective_progress / race.dist) * 100).toFixed(1) }}%)
                </span> 
                <span v-else-if="(race.collective_goal || race.dist) && !race.hide_collective_goal">
                  {{ $helpers.getGoalDistanceForDisplay($options, race, meta.collective_progress, event) }}
                  of
                  {{ $helpers.getGoalDistanceForDisplay($options, race, race.collective_goal || race.dist, event) }}
                  ({{ ((meta.collective_progress / (race.collective_goal || race.dist)) * 100).toFixed(1) }}%)
                </span>
                <span v-else>{{ $helpers.getGoalDistanceForDisplay($options, race, meta.collective_progress, event) }}</span>
              </v-chip>
              <div v-if="(race.collective_goal || race.dist) && $helpers.isTimeInBetween(race.from || event.from, race.till || event.till) && (meta.collective_progress < (race.collective_goal || race.dist))">
                ({{ $t('results.collective-progress-required-to-goal') }}: {{ $helpers.getGoalDistanceForDisplay($options, race, requiredScoreToReachGoal, event) }}, 
                {{ percentageDiffFromGoal.toFixed(1) }}% {{ requiredScoreToReachGoal < meta.collective_progress ? $t('result.collective-progress-ahead-schedule') : $t('result.collective-progress-behind-schedule') }})
                <v-icon small :title="$t('results.collective-progress-required-to-goal-tooltip')">fa-question-circle</v-icon>
              </div>
            </td>
          </tr>
          <tr v-for="(item, idx) in results" :key="idx" @click="openResultPopup(item)">
            <template v-for="(column, col) in columns" :class="item.css_class">
              <td v-if="column.type == 'POSITION'" :key="col" :class="column.css_class">
                <v-chip v-if="item.status === 'LIVE'" color="red" outlined><v-icon color="red" small class="me-1">fa-record-vinyl fa-pulse</v-icon> {{ $t('results.status.LIVE') }}</v-chip>
                <span v-else-if="item.status">{{ $helpers.getStatusForDisplay(this, item.status) }}</span>
                <v-chip v-else-if="item.pos" :color="filter && filter.gender ? 'gray' : 'accent'">{{ item.pos }}</v-chip>
              </td>
              <td v-if="column.type == 'BIB'" :key="col" :class="column.css_class">
                {{ item.bib }}
              </td>
              <td v-else-if="column.type == 'AVATAR'" :key="col" :column="item.css_class">
                <v-avatar size="32"><img v-if="item.img" :title="`Profile Picture of ${item.name}`" :src="item.img+(item.img.indexOf('?')>0?'&':'?')+'w=64&h=64'" onerror="this.style.display='none';"/></v-avatar>
              </td>
              <td v-else-if="column.type == 'NAME'" :key="col" :class="column.css_class">
                {{ item.name }}
                <a v-if="!race.team && meta.has_groups" class="font-s d-block" @click.stop.prevent="search(item.group)">{{ item.group }}</a>
              </td>
              <td v-else-if="column.type == 'GENDER'" :key="col" :class="`pl-0 col-gender ${filter && filter.gender ? 'col-highlighted': ''}`">
                <span :title="$helpers.getGenderForDisplay(item.gender, /*full:*/true)">{{$helpers.getGenderForDisplay(item.gender)}}</span>
                <v-chip v-if="item.gender_pos" outlined class="float-right" color="red darken-4">{{ item.gender_pos }}</v-chip>
              </td>
              <td v-else-if="column.type == 'CATEGORY'" :key="col" :class="`pr-0 col-category ${filter && filter.category ? 'col-highlighted': ''}`">
                <div class="d-flex">
                  <span style="flex-grow: 1;">{{item.cat}}</span>
                  <v-chip v-if="item.cat_pos" outlined class="shrink" color="blue darken-4">{{ item.cat_pos }}</v-chip>
                </div>
              </td>
              <td v-else-if="column.type == 'AWARD'" :key="col" class="pr-0 col-award">
                <v-icon v-if="item.award_pos" :color="$helpers.getAwardPositionColor(item.award_pos)" :title="item.award_cat ? $t('results.details.award-in-category', { pos: item.award_pos, category: item.award_cat }) : $t('results.details.award-message', { pos: item.award_pos})">fa-trophy</v-icon>
              </td>
              <td v-else-if="column.type == 'COUNTRY'" :key="col" :class="column.css_class">
                <span v-if="item.country && item.country.length >= 2 && item.country.length <= 3" :title="item.country" :class="`mr-2 flag-icon flag-icon-${item.country.toLowerCase() }`"></span>
                {{ item.country }}
              </td>
              <td v-else-if="column.type == 'AGE'" :key="col" :class="column.css_class">
                {{ item.age }}
              </td>
              <td v-else-if="column.type == 'STATE'" :key="col" :class="column.css_class">
                {{ item.state }}
              </td>
              <td v-else-if="column.type == 'CITY'" :key="col" :class="column.css_class">
                {{ item.city }}
              </td>
              <td v-else-if="column.type == 'UDF_1'" :key="col" :class="column.css_class">
                {{ item.udf_1 }}
              </td>
              <td v-else-if="column.type == 'UDF_2'" :key="col" :class="column.css_class">
                {{ item.udf_2 }}
              </td>
              <td v-else-if="column.type == 'UDF_3'" :key="col" :class="column.css_class">
                {{ item.udf_3 }}
              </td>
              <td v-else-if="column.type == 'SCORE'" :key="col" :class="column.css_class">
                <v-chip v-if="item.status === 'LIVE'" color="red" outlined><v-icon color="red" small class="me-1">fa-record-vinyl fa-pulse</v-icon> {{ $t('results.status.LIVE') }}</v-chip>                
                <span v-else-if="item.status">{{ $helpers.getStatusForDisplay(this, item.status) }}</span>
                <v-chip v-else outlined :color="event.color || 'green'" class="" :title="$helpers.getScoreTooltipForDisplay($options, race, item, event.unit)">
                  {{ $helpers.getValueForDisplay($options, race, item, event) }}
                  <v-icon v-if="item.official && race.type !== 'EXTERNAL'" x-small :color="event.color || 'green'" class="ml-2" :title="$t('results.official-result')">fal fa-check-double</v-icon>
                  <v-icon v-if="item.verified && race.type !== 'EXTERNAL'" small :color="event.color || 'green'" class="ml-2" :title="$t('results.verified-result')">fal fa-location</v-icon>
                </v-chip>
              </td>
              <td v-else-if="column.type == 'SECONDARY_SCORE'" :key="col" :class="column.css_class">
                {{ $helpers.getSecondaryValueForDisplay($options, race, item.secondary_value, event.unit) }}
              </td>
              <td v-else-if="column.type == 'LAST_ACTIVITY_DATE'" :key="col">
                {{ item.last_activity | localDate('L', /*inUserTime:*/true) }}
              </td>
              <td v-else-if="column.type == 'SPEED'" :key="col">
                {{ item.speed | speed(event.unit, race.activity_types && race.activity_types.length == 1 ? race.activity_types[0] : null) }}
              </td>
              <td v-else-if="column.type == 'PACE'" :key="col">
                {{ item.pace | pace(event.unit, race.activity_types && race.activity_types.length == 1 ? race.activity_types[0] : null) }}
              </td>
              <td v-else-if="column.type == 'STEPS'" :key="col">
                {{ item.steps | steps }} 
              </td>
              <td v-else-if="column.type == 'CUSTOM'" :key="col">
                {{ item.custom }} {{ race.custom }}
              </td>
              <td v-else-if="column.type == 'ACTIVE_DAYS'" :key="col" :class="column.css_class">
                {{ item.active_days }}
              </td>
              <td v-else-if="column.type == 'ACTIVITY_TYPES' && item.activity_types" :key="col" :class="column.css_class">
                <span v-for="(type, idx) in item.activity_types.slice(0, 5)" :key="idx">
                  <v-icon small class="me-2" :title="$helpers.getActivityTypeLabel(type)">{{ $helpers.getActivityIcon(type) }}</v-icon>
                </span>
                <span v-if="item.activity_types.length > 5">+ {{item.activity_types.length-5}}</span>
              </td>
              <td v-else-if="column.type == 'DISTANCE'" :key="col" style="min-width:100px;">
                {{ item.dist | distance(event.unit, false, race.activity_types) }} 
              </td>
              <td v-else-if="column.type == 'ELEVATION'" :key="col" style="min-width:100px;">
                {{ item.elevation_gain | elevation(event.unit) }} 
              </td>
              <td v-else-if="column.type == 'TIME'" :key="col" style="min-width:100px;">
                {{ item.time | duration }} 
              </td>
              <td v-else-if="column.type == 'BONUS'" :key="col" class="px-0" style="width:32px;">
                <ActivityPenaltyScoreChip :base="item.base_pts" :penalty="item.base_pts - item.total_pts" />
                <ActivityBonusScoreChip :base="item.base_pts" :bonus="item.total_pts - item.base_pts" />
              </td>
              <td v-else-if="column.type == 'BADGE'" :key="col" :class="column.css_class">
                <span :set="badge = (item.badges||[]).find(x => x.id == column.badge_id)">
                  <span v-if="badge && badge.format == 'PERCENTAGE'">
                    <v-progress-circular :value="badge.pct * 100" :color="badge.pct < 1 ? 'black' : event.color || 'primary'" size="40" rotate="-90">
                      <span v-if="badge.pct < 1 && event.hide_result_details" style="font-size:70%;">x</span>
                      <span v-else-if="badge.pct < 1" style="font-size:70%;">{{ Math.round(badge.pct * 100) }}%</span>
                      <span v-else-if="badge.pct < 2 || event.hide_result_details" style="font-size:100%;">✔</span>
                      <span v-else style="font-size:100%;">{{Math.floor(badge.pct)}}x</span>
                    </v-progress-circular>
                  </span>
                  <span v-else>
                    {{badge ? (badge.value ? $helpers.getBadgeValueForDisplay($options, event, race, badge.format, badge.value) : '✔') : ''}}
                    <v-icon v-if="race.type !== 'EXTERNAL' && badge && badge.tags && badge.tags.some(x => x === 'IMPORTED')" small color="green" class="ml-2" :title="$t('results.official-result')">fal fa-check-double</v-icon>
                  </span>
                </span>
              </td>
              <td v-else-if="column.type == 'PROGRESS'" :key="col" :class="column.css_class">
                <span v-if="item.progress || item.progress === 0">{{ Math.round(item.progress * 100) }}%</span>
                <span v-else class="grey--text">{{$t('shared.n-a')}}</span>
              </td>
            </template>
            <td v-if="!$vuetify.breakpoint.xs" :style="adminMode ? `min-width:150px;` : ''">
              <v-icon :color="event.color || 'primary'" :title="`Open result by ${item.name}`" @click.stop="openResultPopup(item)">fa-info-circle</v-icon>
              <v-icon v-if="item.photos" class="ml-2" color="grey" @click.stop="openResultPopup(item, 'photos')" :title="`Show Photos for this result by ${item.name}.`">fadl fa fa-images</v-icon>
              <v-icon v-if="item.comments && false" class="ml-2" color="grey">fadl fa fa-comments</v-icon>
              <v-icon v-if="adminMode && !item.id.startsWith('e-')" color="primary" class="ml-2" title="Profile details" @click.stop="$emit('show-profile-details', item)">fa-user</v-icon>
              <v-icon v-if="adminMode" color="primary" class="ml-2" title="Recalculate result" @click.stop="recalculateResult(item)">fa-sync</v-icon>
              <v-icon v-if="adminMode" color="red" class="ml-2" title="Delete result from leaderboard" @click.stop="deleteResult(item)">fa-trash</v-icon>
            </td>
          </tr>
          <tr v-if="results && results.length == 0 && race.top_x !== 0">
            <td colspan="6">
              <i v-if="$helpers.isFutureEvent(event)">{{$t('results.no-results-check-back-soon', { date: $options.filters.localDate(event.from, "L")} )}}</i>
              <i v-else>{{$t('results.no-results-yet')}}</i>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <RaceResultDetailsDialog ref="resultPopup" :event="event" :race="race" :badges="badges" :meta="meta" :filter="filter" :adminMode="adminMode" @close="$emit('detailsClose')"/>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/eventbus.js';
import eventManagerService from "@/services/eventManagerService";
import ActivityScoreChip from "@/components/ActivityScoreChip";
import ActivityBonusScoreChip from "@/components/ActivityBonusScoreChip";
import ActivityPenaltyScoreChip from "@/components/ActivityPenaltyScoreChip";
import RaceResultDetailsDialog from "@/components/RaceResultDetailsDialog";
import TooltipDialog from "@/components/TooltipDialog";
import Vue from "vue";
import resultColumns from '@/util/resultColumns.js'

export default {
  name: "RaceResultGrid",
  components: {
    ActivityScoreChip,
    ActivityBonusScoreChip,
    ActivityPenaltyScoreChip,
    RaceResultDetailsDialog,
    TooltipDialog,
  },
  props: {
      results: Array,
      meta: Object,
      event: Object,
      race: Object,
      badges: Array,
      filter: Object,
      adminMode: Boolean,
      fixedColumns: Array,
      showSubResults: Boolean,
      showCollective: { type: Boolean, default: true },
  },
  data() {
    return {
      columns: null,
    };
  },
  async mounted() {
    if (this.race && this.meta) {
      this.loadCustomColumns();
    }
    EventBus.$on('tile-show-rankings', async id => {
      await this.closeResultPopup();
    });
  },
  methods: {
    async closeResultPopup() {
      await this.$refs.resultPopup.close();
    },
    async openResultPopup(result, section) {
      console.log ('opening popup', result);
      await this.$refs.resultPopup.open(result, null, null, null, section);
    },
    async search(query, e) {
      console.log ('requesting result search', query, e);
      this.$emit('search', query); 
    },
    async deleteResult(result) {
      if (this.adminMode && confirm(`Are you sure you want to delete the result ${result.name} from the leaderboard?`)) {
        var response = (await eventManagerService.deleteResult(this.event.id, this.race.id, result.id)).data;
        console.log('Saved', response);
        if (response.status == "OK") {
          this.$emit('refresh'); 
        }
      }
    },
    async recalculateResult(result) {
      if (this.adminMode && confirm(`Are you sure you want to recalculate the result ${result.name}? This may take a few seconds.`)) {
        var response = (await eventManagerService.recalculateResult(this.event.id, this.race.id, result.id)).data;
        console.log('Recalculation response', response);
        if (response.status == "OK") {
          this.$emit('refresh'); 
        }
      }
    },

    getInitials(name) {
      var initials = name.match(/\b\w/g) || [];
      initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
      console.log(name, initials)
      return initials.substring(0, 2);
    },

    loadCustomColumns() {
      if (this.fixedColumns) {  
        this.columns = this.fixedColumns;
        return;
      }
      if (this.race && this.meta) {
        this.columns = resultColumns.mergeAndFilterResultColumns(this.event, this.race, this.meta, this.race.cols, this.$vuetify.breakpoint.xs);
        //console.log('Column config:', this.columns, 'config', this.race.cols);
      }
    }
  },
  computed: {
    ratioCompleted() {
      let daysActive = Math.ceil(this.$helpers.getStartDurationForRace(this.event, this.race).length('days')); 
      let daysTotal = this.$helpers.getDurationForRace(this.event, this.race).length('days');
      return daysActive / daysTotal;
    },
    requiredScoreToReachGoal() {
      //return 1600000;
      return Math.round((this.race.collective_goal || this.race.dist) * this.ratioCompleted);
    },
    percentageDiffFromGoal() {
      let relativeDiff = Math.round(Math.abs(((this.meta.collective_progress / this.requiredScoreToReachGoal)-1)*100));
      let actualCompleted = this.meta.collective_progress / (this.race.collective_goal || this.race.dist);
      let absoluteDiff = Math.abs((actualCompleted-this.ratioCompleted)*100.0);
      console.log('/// relative diff', relativeDiff, 'ratio', this.ratioCompleted, 'actual', actualCompleted, 'abs diff', absoluteDiff.toFixed(1));

      return absoluteDiff;//Math.round(Math.abs(((this.meta.collective_progress / this.requiredScoreToReachGoal)-1)*100));
    },
    showCollectiveProgress() {
      return this.race.collective && this.meta.collective_progress && this.showCollective;
    },
    showRanking() {
      return !this.race.participation;
    },
 
  },
  watch: {
    race() {
      this.loadCustomColumns();
    },
    meta() {
      this.loadCustomColumns();
    },
    fixedColumns() {
      this.loadCustomColumns();
    },
  }
};
</script>
<style lang="scss">
.col-highlighted {background-color: #FFFDE7 !important;}
.col-avatar { margin: 0 !important; padding: 0 !important;}
.col-avatar.first { padding-left: 16px !important;}
.col-name {min-width:120px; font-weight: bold; padding-left: 8px !important ;}
.col-gender { padding: 0 8px!important; min-width:85px; max-width:100px;width: 85px; color: #B71C1C !important;}
.col-category { padding: 0 8px!important; min-width:120px; max-width:150px; width: 110px; color: #0D47A1 !important;}
.col-gender > span:not(.v-chip), .col-category > span:not(.v-chip) { line-height: 2rem /* center vertically */;;}
.col-age { min-width:50px; max-width:75px;width: 85px; }
</style>

